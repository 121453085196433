import { Box } from '@mui/material';
import { TRoles } from 'types';
import { useUserSettingsTabs } from 'utils';

import {
  Invoice,
  ActivationCode,
  SelfRegistration,
  Onboarding,
  CompanyCustomField,
  Security,
} from './SettingsTabs';

export interface SettingsAccountProps {
  activeTab: number;
  userRoles: TRoles;
  isShowTab: (tab: { value: string; label: string; role: TRoles }) => boolean;
}

const SettingContent: React.FC<SettingsAccountProps> = ({ activeTab, userRoles, isShowTab }) => {
  //very strange logic, I hope somebody rewrite it who will do CCP-630
  //current logic needs to correct show userAccountTabs after refresh page(by F5) if permissions were changed
  const userSettingsTabs = useUserSettingsTabs();
  const displayedUserSettings = userSettingsTabs.map((tab, index) => ({
    show: isShowTab(tab),
    idx: index,
  }));
  const userAccountTabs = [
    <Invoice key='1' />,
    <ActivationCode key='2' />,
    <SelfRegistration key='3' />,
    <Onboarding key='4' />,
    <CompanyCustomField key='5' />,
    <Security key='6' />,
  ].map((item, index) => (displayedUserSettings[index].show ? item : <div key={index}></div>));

  return <Box className='content'>{userAccountTabs[activeTab]}</Box>;
};

export default SettingContent;
