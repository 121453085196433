import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { AxiosConfig } from '../interfaces';
import awsConfig from 'aws-exports';

export const patchHeaders = {
  headers: {
    'content-type': 'application/merge-patch+json',
  },
};

const logoutUser = () => {
  const activeCompanyId = window.localStorage.getItem('activeCompanyId') || '';
  window.localStorage.clear();
  window.localStorage.setItem('lastOpenedPathname', window.location.pathname);
  //we must save active company id to local storage after clearing
  window.localStorage.setItem('activeCompanyId', activeCompanyId);
  const defaultSettings: Record<string, string> = { language_app: 'de' };
  window.location.href = `${window.location.origin}/${
    document.cookie.split('; ').reduce((result, item) => {
      const cookie = item.split('=');
      if (cookie[1].trim() === '') {
        return result;
      }
      return { ...result, ...JSON.parse(`{"${cookie[0]}": "${cookie[1]}"}`) };
    }, defaultSettings).language_app
  }/login?session=expired`;
};

export const axiosWithConfig = async ({
  withAuthentication = true,
  headers = {},
}: AxiosConfig = {}): Promise<AxiosInstance> => {
  const customHeaders: AxiosRequestHeaders = { ...headers };
  try {
    if (withAuthentication) {
      //check current credentials to understand that credentials is not expired
      //we must have this block to catch amplify issue with "No current user"
      const lastUserKey = `CognitoIdentityServiceProvider.${awsConfig.aws_user_pools_web_client_id}.LastAuthUser`;
      if (!localStorage.getItem(lastUserKey)) {
        const err = new Error();
        err.name = 'NotAuthorizedException';
        throw err;
      }

      const currentSession = await Auth.currentSession();
      customHeaders['Authorization'] = 'Bearer ' + currentSession.getIdToken().getJwtToken();
    }
  } catch (err) {
    if (err instanceof Error && err.name === 'NotAuthorizedException') {
      logoutUser();
    }
  }
  return axios.create({
    timeout: 30000,
    headers: customHeaders,
  });
};
