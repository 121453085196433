import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  RadioGroup,
  Typography,
} from '@mui/material';
import { CCPDefaultButton, CCPSelect, Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { UserManagementSelectors } from 'features/userManagement/store/userManagement.selectors';
import { SettingsActions } from 'features/settings/store';
import { ActivationCodeSchema } from 'shared/schemas';
import { useAppDispatch, useAppSelector } from 'store';
import { FetchingStatus, ListType, RootState, UserTypeShortcut } from 'types';
import {
  ActivationCodeWrapper,
  CtaContainer,
  Form,
  RadioContainer,
  RadioDescription,
  StyledRadio,
} from './ActivationCode.styles';
import { ActivationCodeForm } from './interfaces';
import DistributionList from './components/DistributionList';
import Loader from '@components/Loader';
import { GlobalStateActions } from '@store/globalState';

const ActivationCode = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    guardPopups: { showActivationLettersAddUser },
  } = useAppSelector(state => state.globalState);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const {
    fetchingStatus: settingsFetchingStatus,
    activationLetterRecipientMode: defaultRecipientMode,
  } = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );

  const employeeManagementUsers = useAppSelector((rootState: RootState) =>
    UserManagementSelectors.getUniqueUserMgmtUsers(rootState, activationLetterDistributionList),
  );

  useEffect(() => {
    if (showActivationLettersAddUser) {
      setIsModalOpen(true);
      dispatch(GlobalStateActions.showActivationLettersAddUserPopup(false));
    }
  }, [showActivationLettersAddUser, dispatch]);

  const methods = useForm<ActivationCodeForm>({
    defaultValues: {
      activationLetterRecipientMode: 'eu',
      employeeManagementUsers: [],
      selectedEmployeeMngUser: '',
    },
    mode: 'all',
    resolver: yupResolver(ActivationCodeSchema),
  });

  const { reset, setValue, control, getValues, watch } = methods;
  const selectedEmployeeMngUser = watch('selectedEmployeeMngUser');

  const handleActivationLetterRecipientModeChange = async (value: string) => {
    if (activeCompany) {
      const activationLetterRecipientMode = value as UserTypeShortcut;
      setValue('activationLetterRecipientMode', activationLetterRecipientMode);

      if (activationLetterRecipientMode === 'cu' && !activationLetterDistributionList.length) {
        dispatch(GlobalStateActions.setActivationLettersPopupState(true));
        return;
      }

      const resp = await dispatch(
        SettingsActions.updateSettings({
          companyId: activeCompany.companyId,
          activationLetterRecipientMode,
        }),
      );
      if (resp.meta.requestStatus === 'rejected') {
        setValue('activationLetterRecipientMode', defaultRecipientMode);
      }
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setValue('selectedEmployeeMngUser', '');
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleAddUserToList = async () => {
    if (activeCompany) {
      dispatch(
        SettingsActions.addRecordToDistributionList({
          companyId: activeCompany.companyId,
          email: selectedEmployeeMngUser,
          listType: ListType.ACTIVATION_LETTER,
        }),
      );
      dispatch(
        SettingsActions.updateSettings({
          companyId: activeCompany.companyId,
          activationLetterRecipientMode: 'cu',
        }),
      );
      handleClose();
    }
  };

  useEffect(() => {
    if (activeCompany) {
      const { companyId } = activeCompany;
      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );

      // we don't need requesting settings when fetching status is pending,
      // because update settings executing longer than get settings and in this case
      // sometimes user can see guard popup while settings is updating https://hansefit.atlassian.net/browse/FE-1015
      if (settingsFetchingStatus !== FetchingStatus.PENDING) {
        dispatch(SettingsActions.getSettings({ companyId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeCompany]);

  useEffect(() => {
    if (
      settingsFetchingStatus === FetchingStatus.FULFILLED &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED
    ) {
      reset({
        activationLetterRecipientMode: defaultRecipientMode,
        employeeManagementUsers,
        selectedEmployeeMngUser: '',
      });
    }
  }, [
    activationLetterDistributionList,
    activationLetterDistributionListFetchingStatus,
    reset,
    settingsFetchingStatus,
    defaultRecipientMode,
    employeeManagementUsers,
  ]);

  //show guard popup when list had users, but all users were removed
  useEffect(() => {
    if (
      settingsFetchingStatus === FetchingStatus.FULFILLED &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED &&
      defaultRecipientMode === 'cu' &&
      activationLetterDistributionList.length === 0
    ) {
      dispatch(GlobalStateActions.setActivationLettersPopupState(true));
    }
  }, [
    activationLetterDistributionList,
    defaultRecipientMode,
    dispatch,
    settingsFetchingStatus,
    activationLetterDistributionListFetchingStatus,
  ]);

  return (
    <ActivationCodeWrapper id='activationCodeWrapper'>
      <Loader
        show={
          settingsFetchingStatus === FetchingStatus.PENDING ||
          activationLetterDistributionListFetchingStatus === FetchingStatus.PENDING
        }
        inCard={!isModalOpen}
        portalId='activationCodeWrapper'
      />
      {defaultRecipientMode && (
        <>
          <Typography variant='h6'>
            {intl.formatMessage({
              id: 'setting.activation_code.title',
              defaultMessage: 'Activation code settings',
            })}
          </Typography>
          <Spacer size='md' />

          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'setting.activation_code.description',
              defaultMessage:
                'Description text, for example: select receiver of the activation code.',
            })}
          </Typography>

          <Spacer height={48} />

          <FormProvider {...methods}>
            <Form>
              <Box className='content__fields_wrapper'>
                <RadioGroup
                  name='activationLetterRecipientMode'
                  value={getValues('activationLetterRecipientMode')}
                  onChange={event => handleActivationLetterRecipientModeChange(event.target.value)}>
                  <RadioContainer>
                    <FormControlLabel
                      value='eu'
                      control={<StyledRadio />}
                      label={intl.formatMessage({
                        id: 'user_setting.activation_code.send_to_eu.label',
                        defaultMessage: 'Send activation codes to end user',
                      })}
                    />
                    <RadioDescription>
                      {intl.formatMessage({
                        id: 'user_setting.activation_code.send_to_eu.description',
                        defaultMessage:
                          'All activation codes are sent directly to end users via email.',
                      })}
                    </RadioDescription>
                  </RadioContainer>

                  <Spacer height={28} />
                  <RadioContainer>
                    <FormControlLabel
                      value='cu'
                      control={<StyledRadio />}
                      label={intl.formatMessage({
                        id: 'user_setting.activation_code.send_to_cu.label',
                        defaultMessage: 'Send activation codes to company user',
                      })}
                    />
                    <RadioDescription>
                      {intl.formatMessage({
                        id: 'user_setting.activation_code.send_to_cu.description',
                        defaultMessage:
                          'All activation codes are sent to selected company users via email.',
                      })}
                    </RadioDescription>
                  </RadioContainer>
                </RadioGroup>
              </Box>
              {activationLetterDistributionList.length > 0 &&
                getValues('activationLetterRecipientMode') === 'cu' && (
                  <>
                    <Spacer height={30} />
                    <DistributionList />
                  </>
                )}
              <>
                <Spacer height={30} />
                {getValues('activationLetterRecipientMode') === 'cu' && (
                  <CtaContainer>
                    <CCPDefaultButton variant='contained' onClick={handleOpenModal}>
                      {intl.formatMessage({
                        id: 'user_setting.activation_code.cta.add_new_recipient',
                        defaultMessage: 'Add new recipient',
                      })}
                    </CCPDefaultButton>
                  </CtaContainer>
                )}
              </>
              <Dialog open={isModalOpen}>
                <DialogTitle>
                  {intl.formatMessage({
                    id: 'user_setting.activation_code.select_company_user.header',
                    defaultMessage: 'Select company user',
                  })}
                </DialogTitle>
                <DialogContent>
                  <Typography variant='subtitle1'>
                    {intl.formatMessage({
                      id: 'user_setting.activation_code.select_company_user.description',
                      defaultMessage:
                        'Description text, for example: select CU of the activation code.',
                    })}
                  </Typography>
                  <Spacer size='2xl' />
                  <CCPSelect
                    label={intl.formatMessage({
                      id: 'user_setting.activation_code.select_company_user.dropdown',
                      defaultMessage: 'Company user',
                    })}
                    required
                    name='selectedEmployeeMngUser'
                    control={control}>
                    {getValues('employeeManagementUsers').map(user => (
                      <MenuItem
                        key={user.cognitoId}
                        value={user.email}
                        selected={user.cognitoId === selectedEmployeeMngUser}>
                        {user.name} {user.surname} ({user.email})
                      </MenuItem>
                    ))}
                  </CCPSelect>
                </DialogContent>
                <DialogActions>
                  <CCPDefaultButton variant='outlined' onClick={handleClose}>
                    {intl.formatMessage({
                      id: 'user_setting.activation_code.select_company_user.back_to_list',
                      defaultMessage: 'Back to list',
                    })}
                  </CCPDefaultButton>
                  <CCPDefaultButton
                    disabled={!selectedEmployeeMngUser}
                    variant='contained'
                    onClick={handleAddUserToList}>
                    {intl.formatMessage({
                      id: 'user_setting.activation_code.select_company_user.add_company_user',
                      defaultMessage: 'Add company user',
                    })}
                  </CCPDefaultButton>
                </DialogActions>
              </Dialog>
            </Form>
          </FormProvider>
        </>
      )}
    </ActivationCodeWrapper>
  );
};

export default ActivationCode;
