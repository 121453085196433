import { styled, Box } from '@mui/material';

export const CouponUsageWrapper = styled(Box)(({ theme }) => ({
  '.container': {
    gap: 2,
    display: 'flex',
    minHeight: 770,
    height: 'auto',
  },

  '.content': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'auto',
    background: theme.palette.background.paper,
    borderRadius: '0 4px 4px 0',
  },

  '.coupon_table': {
    borderRadius: 'unset',
  },
}));
