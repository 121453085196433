export const linkRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const pdfReg = /\.(PDF)$/i;
export const jsonReg = /\.(JSON)$/i;

//if we will upgrade this regex we need to update regex in the Signup project
// eslint-disable-next-line no-useless-escape
export const phoneRegExp = /^[0-9]{1,4}[0-9 \-\/]{5,20}$/;

export const signupPasswordRegExp = /^\S+\S{9,99}$/; // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{10,99}$/;
export const euShareRegExp = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
