export { default as Invoice } from './Invoice';
export { default as Onboarding } from './Onboarding';
export { default as Notification } from './Notification';
export { default as SelfRegistration } from './SelfRegistration';
export { default as CompanyCustomField } from './CompanyCustomField';
export { default as ActivationCode } from './ActivationCode';
export { default as Security } from './Security';

export const distributionTableColumns = [
  {
    columnName: 'email',
    columnTitle: 'Email',
    intlId: 'user_setting.self_registration.table.email',
  },
  {
    columnName: 'dateAdded',
    columnTitle: 'Date added',
    intlId: 'user_setting.self_registration.table.date_added',
  },
];
