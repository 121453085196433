import { Box, FormControl, Menu, styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'open',
})<{ isOpenSidebar: boolean }>(({ theme, isOpenSidebar }) => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  width: '100%',
  '.gridToolbarItems': {
    gap: 15,
    [theme.breakpoints.down('xl')]: {
      gap: 13,
    },
    '.grid-button': {
      [isOpenSidebar ? theme.breakpoints.down('lg') : theme.breakpoints.down('md')]: {
        fontSize: 0,
        padding: '7px',
        '& .MuiButton-startIcon': {
          marginRight: 0,
        },
      },
    },
  },
  '&.employee-grid-wrapper': {
    maxWidth: '100%',
    '.gridToolbarItems': {
      [theme.breakpoints.down('xl')]: {
        gap: 4,
      },
    },
    '.grid-button': {
      [theme.breakpoints.down('xl')]: {
        padding: '7px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 0,
        padding: '7px',
        '& .MuiButton-startIcon': {
          marginRight: 0,
        },
      },
    },
  },
}));

export const DataGridProTable = styled(DataGridPro)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  borderRadius: 4,
  '--DataGrid-pinnedBackground': theme.palette.background.paper,
  '--DataGrid-containerBackground': theme.palette.background.paper,
  '--DataGrid-rowBorderColor': theme.palette.background.paper,

  '.MuiDataGrid-row.MuiDataGrid-row--firstVisible': {
    '--rowBorderColor': theme.palette.background.paper,
  },

  '.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover':
    {
      backgroundColor: '#F5F5F5',
    },

  '.MuiDataGrid-columnHeaders': {
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
  },
  '.MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.neutralDark.main,
    fontFamily: 'Roboto-Medium',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  '.MuiDataGrid-cellContent': {
    fontSize: 16,
  },
  '.MuiDataGrid-main': {
    overflow: 'inherit',
    '&>div': {
      pointerEvents: 'unset !important',
    },
  },
  '.noResults': {
    minHeight: '66vh !important',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-cell': {
    borderTop: 0,
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
    alignContent: 'center',
  },
  '.MuiDataGrid-selectedRowCount': {
    fontSize: 16,

    width: 'fit-content',
    minWidth: '20%',
  },
  '.MuiDataGrid-footerContainer': {
    border: 'none',
  },

  '.MuiInputBase-root': {
    height: '40px',
    marginRight: '8px',
  },
}));

export const CustomFormControl = styled(FormControl)(({ theme }) => ({
  '.MuiInputLabel-formControl.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
    maxWidth: 'calc(133% - 24px)',
  },
  '.MuiSelect-icon': {
    marginRight: 17,
    color: theme.palette.neutralDark.main,
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  '.Mui-disabled': {
    color: theme.palette.transparent.dark05,
  },
  '.MuiInputBase-formControl.Mui-disabled': {
    backgroundColor: theme.palette.transparent.disabledInput,
  },
  '.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.transparent.disabledInput,
  },
  '.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
  },
}));

export const MenuWrapper = styled(Menu)(({ theme }) => ({
  '.MuiMenuItem-attention': {
    color: theme.palette.error.main,
  },
}));
