import { useNavigate } from 'react-router-dom';
import { GridRenderCellParams, GridRowId } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTableColumns } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';

import PendingModal from './PendingModal';
import EmployeeUserId from './Columns/UserId';
import { EmployeeNameInstance } from './Columns/FullName';
import EmployeeStatusChip from './Columns/Status';
import { Wrapper } from './EmployeeTable.styles';

import { useLanguage } from 'hooks';
import {
  Employee,
  EmployeeStatus,
  EmployeeStatusTransition,
  EmployeeTableOverwiewData,
  FetchingStatus,
  TransitionStatus,
} from 'types';
import { FormatDate } from 'components/FormatDate';
import EmployeeMoreExpander from './Columns/EmployeeMoreExpander';
import TableGrid, { GRID_ACTIONS_COLUMN_NAME } from 'components/TableGrid';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { useIntl } from 'react-intl';
import { getUniqueSingleSelectList } from 'components/TableGrid/helpers/getUniqueSingleSelectList';
import { getDateFilterOperators } from 'components/TableGrid/CustomFilters/Date';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import BulkActionsModal from '../Modals/BulkActionsModal';
import getEmployeeInitStatus from 'utils/getEmployeeInitialStatus';
import { checkTypeParameter } from 'utils/contractDependencies';
import BulkActionsResponseModal from '../Modals/BulkActionsResponseModal';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import getCustomFieldEnumLabel from '@utils/getCustomFieldEnumLabel';
import { EmployeeBulkActions } from '../Modals/BulkActionsModal/interfaces';
import { getStatusInfo } from '@utils/employeeOverview';

type BulkActionOptions = Array<{
  option: keyof typeof EmployeeBulkActions;
  value: string;
  accent: boolean;
  transition: string;
}>;
const EmployeeTable: React.FC<{ isDirectPay: boolean }> = ({ isDirectPay }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useLanguage();
  const { formatMessage } = useIntl();

  const bulkModalInitState = {
    isOpen: false,
    transition: '',
    action: '',
    rowsAffected: 0,
    employeesList: [] as Employee[],
    currentStatus: [''],
    entryDate: '',
    hideStartDate: false,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [bulkActionOptions, setBulkActionOptions] = useState<BulkActionOptions>([]);
  const [currentEmployee, setCurrentEmployee] = useState<Employee>();
  const [bulkModal, setBulkModal] = useState(bulkModalInitState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkErrorResponseOpen, setBulkErrorResponseOpen] = useState(false);

  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const company = useAppSelector(CompanySelectors.getApi).getCompany.data;
  const { data: allEmployees, fetchingStatus } = useAppSelector(
    EmployeeManagementSelectors.getGetEmployeesApi,
  );
  const employees = useAppSelector(EmployeeManagementSelectors.getTableOverviewEmployees);
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const titleExists = employees.some(employee => employee.userTitle);
  const phoneExists = employees.some(employee => employee.phoneNumber);

  const setCustomDDLVals = useCallback(
    (employees: EmployeeTableOverwiewData[]) =>
      employees.map(employee => {
        const newEmployee = { ...employee, customFields: { ...employee.customFields } };
        if (company?.customFields) {
          company?.customFields.forEach(cf => {
            newEmployee.customFields[cf.key] = getCustomFieldEnumLabel(
              newEmployee.customFields[cf.key],
              cf,
              language,
            );
          });
        }
        return newEmployee;
      }),
    [company?.customFields, language],
  );

  const memoMassagedRows = useMemo(
    () => setCustomDDLVals(employees),
    [employees, setCustomDDLVals],
  );

  const selectRows = (selectedRowsIds: []) => {
    setSelectedRows([...selectedRowsIds]);
  };

  const getAffectedEmployees = useCallback(
    (employeeInitStatus: string | EmployeeStatus[] | string[]) =>
      selectedRows
        .map(id => (employees.find(employee => employee.id === id) || {}) as Employee)
        .filter(employee => employeeInitStatus.includes(employee.status)),
    [selectedRows, employees],
  );

  const [bulkChangedEmployees, setBulkChangedEmployees] = useState([]);
  const [bulkUnchangedEmployees, setBulkUnchangedEmployees] = useState([]);
  const [pendingRequests, setPendingRequests] = useState(false);

  const formatStatus = useCallback(
    (row: EmployeeTableOverwiewData) => {
      if (!row) {
        return '';
      }
      if (row.plannedTransition) {
        switch (row.plannedTransition.status) {
          case TransitionStatus.PAUSE: {
            return intl.formatMessage({
              id: 'user.status_search.paused_from',
              defaultMessage: 'Paused from',
            });
          }
          case TransitionStatus.PAUSED_UNLIMITED: {
            return intl.formatMessage({
              id: 'user.status_search.suspending_from',
              defaultMessage: 'Membership suspended from',
            });
          }
          case TransitionStatus.DEACTIVATING:
          case TransitionStatus.DEACTIVATED:
          case TransitionStatus.DELETED: {
            return intl.formatMessage({
              id: 'user.status_search.deactivating_from',
              defaultMessage: 'Deleted from',
            });
          }
          case TransitionStatus.PAUSED:
          case TransitionStatus.PENDING: {
            return intl.formatMessage({
              id: 'user.status.pending.filter',
              defaultMessage: 'Active from',
            });
          }
          default:
            return row.status || '';
        }
      }
      switch (row.status) {
        case EmployeeStatus.NEW:
          return intl.formatMessage({ id: 'user.status.new', defaultMessage: 'New' });

        case EmployeeStatus.PENDING:
          return intl.formatMessage({
            id: 'user.status.pending.filter',
            defaultMessage: 'Eligible from',
          });
        case EmployeeStatus.ACTIVE:
        case EmployeeStatus.ELIGIBLE:
          return intl.formatMessage({ id: 'user.status.active', defaultMessage: 'Eligible' });
        case EmployeeStatus.LOCKED:
        case EmployeeStatus.BLOCKED:
          return intl.formatMessage({ id: 'user.status.blocked', defaultMessage: 'Blocked' });
        case EmployeeStatus.PAUSED:
          return intl.formatMessage({ id: 'user.status.paused', defaultMessage: 'Paused' });
        case EmployeeStatus.APPLIED:
          return intl.formatMessage({ id: 'user.status.applied', defaultMessage: 'Applied' });
        case EmployeeStatus.INACTIVE:
          return intl.formatMessage({ id: 'user.status.inactive', defaultMessage: 'Inactive' });
        case EmployeeStatus.DENIED:
          return intl.formatMessage({ id: 'user.status.denied', defaultMessage: 'Denied' });
        default:
          return row.status || '';
      }
    },
    [intl],
  );

  const isBulkActionsButtonDisabled = useCallback(() => {
    if (selectedRows.length > 0) {
      if (
        settingsSettings.activationLetterRecipientMode.toLowerCase() === 'eu' &&
        getAffectedEmployees(getEmployeeInitStatus('RESEND')).filter(
          employee => !employee.businessEmail,
        ).length === selectedRows.length
      ) {
        return true;
      }
      return false;
    }
  }, [selectedRows, getAffectedEmployees, settingsSettings.activationLetterRecipientMode]);

  const openBulkActionModal = (value: keyof typeof EmployeeBulkActions) => {
    if (value === 'DEFAULT') return;

    const employeeInitStatus = getEmployeeInitStatus(value);
    const affectedEmployees = getAffectedEmployees(employeeInitStatus);
    if (affectedEmployees[0]) {
      let prefilledStartDate =
        employees.find(employee => employee.id === affectedEmployees[0]?.id)?.entryDate || '';
      let hideStartDate = false;
      if (value === 'CONFIRM') {
        for (let i = 0; i < affectedEmployees.length; i++) {
          if (affectedEmployees[i]) {
            const checkDate = employees.find(
              employee => employee.id === affectedEmployees[i]?.id,
            )?.entryDate;
            if (prefilledStartDate === checkDate) continue;
            else {
              prefilledStartDate = '';
              break;
            }
          }
        }
      }
      if (value === 'REMOVE') {
        hideStartDate = !affectedEmployees.some(item => item.status !== EmployeeStatus.PENDING);
      }

      const transition = bulkActionOptions.find(action => action.option === value)?.transition;
      setBulkModal({
        isOpen: true,
        action: EmployeeBulkActions[value],
        transition: transition || '',
        rowsAffected: affectedEmployees.length,
        employeesList: affectedEmployees,
        currentStatus: employeeInitStatus,
        entryDate: prefilledStartDate,
        hideStartDate,
      });
    }
  };

  const submitBulkActions = async ({
    initStatus,
    transition,
    startDate,
    endDate,
    resendLetters,
  }: {
    initStatus: EmployeeStatus[] | string[];
    transition: EmployeeStatus | string;
    startDate?: string;
    endDate?: string;
    resendLetters?: boolean;
  }) => {
    const affectedEmployees = getAffectedEmployees(initStatus);
    const updatedEmployees: EmployeeStatusTransition[] = [];
    const employeeIds: number[] = [];

    if (affectedEmployees.length > 0) {
      affectedEmployees.map(async employee => {
        if (employee) {
          const endUserId = employee.id;

          if (transition === 'RESEND' || resendLetters) {
            employeeIds.push(endUserId);
          }

          if (transition !== 'RESEND') {
            const userId: string = endUserId.toString();
            let employeeStartDate = startDate;
            if (transition === EmployeeStatus.UNASSIGNED) {
              employeeStartDate = employee.status === EmployeeStatus.PENDING ? '' : startDate;
              endDate = '';
            }
            updatedEmployees.push({
              endUserId: userId.toString(),
              transition,
              startDate: employeeStartDate,
              endDate,
            });
          }
        }
      });

      if (company?.id) {
        if (transition !== 'RESEND') {
          await dispatch(
            EmployeeManagementActions.bulkStatusChange({
              companyId: company?.id,
              employeeTransitions: updatedEmployees,
            }),
          ).then(resp => {
            if (resp.payload?.status === 207) {
              setBulkChangedEmployees(resp.payload?.changed);
              setBulkUnchangedEmployees(resp.payload?.unchanged);
              setBulkErrorResponseOpen(true);
            }
          });
          dispatch(EmployeeManagementActions.getEmployees({ companyId: company?.id }));
        }

        if (transition === 'RESEND' || resendLetters) {
          dispatch(
            EmployeeManagementActions.resendActivationLetterBulk({
              endUserIds: employeeIds,
              companyId: company?.id,
            }),
          );
        }
      }
      setBulkModal(bulkModalInitState);
      setSelectedRows([]);
    }
  };

  const statusOptions = useMemo(() => {
    if (!memoMassagedRows) return [];

    return getUniqueSingleSelectList(
      memoMassagedRows.map(item => formatStatus(item)).filter(Boolean),
    ).filter(Boolean);
  }, [memoMassagedRows, formatStatus]);

  const columns = useTableColumns<EmployeeTableOverwiewData>({
    scope: 'employee_management.overview.table.header',
    columnsData: [
      {
        field: 'userId',
        i18nText: 'user_id',
        defaultMessage: 'user id',
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => <EmployeeUserId userId={params.value} />,
        searchType: 'string',
        searchable: true,
      },
      {
        field: 'userTitle',
        minWidth: 80,
        i18nText: 'user_title',
        defaultMessage: 'Title',
        searchType: 'string',
        searchable: true,
      },
      {
        field: 'firstName',
        i18nText: 'first_name',
        defaultMessage: 'First name',
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<EmployeeTableOverwiewData>) => (
          <EmployeeNameInstance
            onClick={handleDetailsRedirect}
            id={params.row.userId}
            employeeName={params.value}
            activeLanguage={language}
          />
        ),
        searchType: 'string',
        searchable: true,
      },
      {
        field: 'lastName',
        i18nText: 'last_name',
        defaultMessage: 'Last name',
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<EmployeeTableOverwiewData>) => (
          <EmployeeNameInstance
            onClick={handleDetailsRedirect}
            id={params.id}
            employeeName={params.value}
            activeLanguage={language}
          />
        ),
        searchType: 'string',
        searchable: true,
      },
      {
        field: 'gender',
        minWidth: 80,
        i18nText: 'gender',
        defaultMessage: 'Gender',
        searchType: 'string',
        searchable: true,
        renderCell: (params: GridRenderCellParams<EmployeeTableOverwiewData>) => (
          <span className='notranslate'>
            {intl.formatMessage({
              id: `form.employee.gender.${params.value}`,
              defaultMessage: params.value,
            })}
          </span>
        ),
      },
      {
        field: 'businessEmail',
        minWidth: 200,
        flex: 1,
        i18nText: 'business_email',
        defaultMessage: 'corporate email',
        searchType: 'string',
        searchable: true,
        renderCell: (params: GridRenderCellParams<EmployeeTableOverwiewData>) => (
          <span className='notranslate'>{params.value}</span>
        ),
      },
      {
        field: 'birthDate',
        minWidth: 120,
        i18nText: 'birth_date',
        filterOperators: getDateFilterOperators(false),
        defaultMessage: 'Birthdate',
        renderCell: params => FormatDate(params.value),
      },
      {
        field: 'phoneNumber',
        minWidth: 200,
        i18nText: 'phone_number',
        defaultMessage: 'Phone number',
        searchType: 'string',
        searchable: true,
      },
      {
        field: 'status',
        i18nText: 'status',
        defaultMessage: 'status',
        type: 'singleSelect',
        valueOptions: statusOptions,
        renderCell: (params: GridRenderCellParams<EmployeeTableOverwiewData>) => {
          const statusInfo = getStatusInfo(params.row);
          return (
            <EmployeeStatusChip
              status={statusInfo.status}
              openModalToggler={openModalToggler}
              userId={params.id as number}
              date={statusInfo.date}
              plannedStatus={statusInfo.plannedStatus}
            />
          );
        },
        valueGetter: (_, row) => formatStatus(row),
      },
      {
        field: 'entryDate',
        minWidth: 120,
        i18nText: 'start_date',
        filterOperators: getDateFilterOperators(false),
        defaultMessage: 'Start date',
        renderCell: params => FormatDate(params.value),
      },
      {
        field: GRID_ACTIONS_COLUMN_NAME,
        i18nText: 'Actions',
        defaultMessage: 'Actions',
        renderHeader: () => <div></div>,
        width: 50,
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<EmployeeTableOverwiewData>) =>
          params?.row.status === EmployeeStatus.LOCKED ? (
            <></>
          ) : (
            <EmployeeMoreExpander
              userTitle={params.row.userTitle}
              companyId={company?.id}
              userFullName={`${params.row.firstName} ${params.row.lastName}`}
              userId={params.row.id}
              userStatus={params.row.status}
              userEntryDate={params.row.entryDate}
              hasBusinessEmail={Boolean(params.row?.businessEmail)}
              isDirectPay={isDirectPay}
            />
          ),
      },
    ],
    customFields: company?.customFields,
    globalCustomFields: company?.globalCustomFields,
  });

  useEffect(() => {
    setPendingRequests(employees.some(employee => employee.status === EmployeeStatus.APPLIED));
    const newBulkActionOptions: BulkActionOptions = [
      {
        option: 'RESEND',
        value: 'Resend activation letter',
        accent: false,
        transition: 'RESEND',
      },
    ];
    if (!isDirectPay && checkTypeParameter(activeContract, 'pauseContractPossible')) {
      newBulkActionOptions.push({
        option: 'PAUSE',
        value: 'Pause membership',
        accent: false,
        transition: EmployeeStatus.PAUSED,
      });
    }
    newBulkActionOptions.push({
      option: 'EDIT_START_DATE',
      value: 'Edit start date',
      accent: false,
      transition: EmployeeStatus.ACTIVE,
    });
    if (!isDirectPay && checkTypeParameter(activeContract, 'reactivationAllowed')) {
      newBulkActionOptions.push({
        option: 'REACTIVATE',
        value: 'Reactivate membership',
        accent: false,
        transition: EmployeeStatus.ACTIVE,
      });
    }
    if (pendingRequests) {
      newBulkActionOptions.push({
        option: 'CONFIRM',
        value: 'Confirm Request',
        accent: false,
        transition: EmployeeStatus.PENDING,
      });
      newBulkActionOptions.push({
        option: 'DENY',
        value: 'Deny Request',
        accent: false,
        transition: EmployeeStatus.DENIED,
      });
    }
    if (!isDirectPay && checkTypeParameter(activeContract, 'endContractPossible')) {
      newBulkActionOptions.push({
        option: 'DEACTIVATE',
        value: 'Deactivate',
        accent: false,
        transition: EmployeeStatus.INACTIVE,
      });
    }
    if (checkTypeParameter(activeContract, 'deleteEu')) {
      newBulkActionOptions.push({
        option: 'REMOVE',
        value: 'Remove',
        accent: true,
        transition: EmployeeStatus.UNASSIGNED,
      });
    }
    setBulkActionOptions(newBulkActionOptions);
  }, [activeContract, employees, pendingRequests, isDirectPay]);

  const handleDetailsRedirect = useCallback(
    (id: GridRowId, edit?: boolean) => {
      navigate(`/${language}/employee-management/${id}/general-info/${edit ? 'edit' : 'view'}`);
    },
    [navigate, language],
  );

  const openModalToggler = (userId?: number) => {
    if (allEmployees && allEmployees.length > 0) {
      if (userId) {
        setCurrentEmployee(allEmployees.find(employee => employee.id === userId));
      } else {
        setCurrentEmployee(undefined);
      }
      setIsOpen(!isOpen);
    }
  };

  const addNewUser = () => {
    dispatch(
      EmployeeManagementActions.setAddEmployeeModalOpen({
        isOpen: true,
      }),
    );
  };

  return (
    <>
      {bulkModal.isOpen && (
        <BulkActionsModal
          transitionType={bulkModal.transition}
          actionType={bulkModal.action}
          employeesList={bulkModal.employeesList}
          employeesAffected={bulkModal.rowsAffected}
          employeeInitStatus={bulkModal.currentStatus}
          hideStartDate={bulkModal.hideStartDate}
          entryDate={bulkModal.entryDate}
          open
          close={() => setBulkModal(bulkModalInitState)}
          onSubmit={submitBulkActions}
        />
      )}
      <Wrapper>
        {bulkErrorResponseOpen && (
          <BulkActionsResponseModal
            open={bulkErrorResponseOpen}
            close={() => setBulkErrorResponseOpen(false)}
            changedEmployees={bulkChangedEmployees}
            unchangedEmployees={bulkUnchangedEmployees}
          />
        )}
        <TableGrid
          tableName='employee'
          addNewRecords={addNewUser}
          columns={
            titleExists && phoneExists
              ? columns
              : columns.filter(column =>
                  !titleExists ? column.field !== 'userTitle' : column.field !== 'phoneNumber',
                )
          }
          rowsData={memoMassagedRows}
          getRowId={row => row.id}
          isLoading={fetchingStatus === FetchingStatus.PENDING}
          checkboxSelection
          checkboxSelectionVisibleOnly
          rowsSelected={selectedRows}
          selectedRows={selectRows}
          selectable={param => param.row.status !== EmployeeStatus.LOCKED}
          searchPlaceholder={formatMessage({
            id: 'employee_management.search.placeholder',
            defaultMessage: 'Search by PIMcore ID, name or other',
          })}
          noRecordsTranslation={formatMessage({
            id: 'employee_management.grid.no_records',
            defaultMessage: `Sorry, we couldn’t find any employees.`,
          })}
          addRecordsTranslation={formatMessage({
            id: 'employee_management.grid.add_employee',
            defaultMessage: '<tag>Click here</tag> to add new employees',
          })}
          showExportExcel
          hasBulkActions
          bulkActions={bulkActionOptions}
          bulkActionHandler={openBulkActionModal}
          bulkActionsDisableButton={isBulkActionsButtonDisabled()}
        />
      </Wrapper>
      <PendingModal
        employee={currentEmployee || null}
        closeModal={openModalToggler}
        isOpen={isOpen}
      />
    </>
  );
};

export default EmployeeTable;
