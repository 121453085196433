import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig, patchHeaders } from 'api';
import { RootState, BaseErrorResponse } from 'types';

export const setRegistrationSettings = createAsyncThunk<any, any>(
  'settings/setRegistrationSettings',
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const companyId = state.company.activeCompany?.companyId;
    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}/setup`;

    const axios = await axiosWithConfig();

    try {
      await axios.patch(
        endpoint,
        {
          signupPassword: state.settings.selfRegistration.passwordRequired
            ? state.settings.selfRegistration.signupPassword
            : '',
          validEmailDomains: state.settings.selfRegistration.domainValidation
            ? state.settings.selfRegistration.validEmailDomains
            : '',
          paymentProviders: state.settings.selfRegistration.allowSEPA ? ['sepa'] : null,
          invitationRequired: state.settings.selfRegistration.invitationRequired ? 'yes' : 'no',
          autoConfirmSignup: state.settings.selfRegistration.autoConfirmSignup ? 'yes' : 'no',
          showLandingPage: state.settings.selfRegistration.showLandingPage ? 'yes' : 'no',
          isUrlPublished: state.settings.selfRegistration.directPay
            ? null
            : state.settings.selfRegistration.isUrlPublished
            ? 'yes'
            : 'no',
        },
        patchHeaders,
      );
      let generalResp = null;
      if (
        Number(state.settings.defaultSelfRegistration.euShare) !==
        Number(state.settings.selfRegistration.euShare)
      ) {
        generalResp = await axios.patch(
          `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}/general`,
          { euShare: Number(state.settings.selfRegistration.euShare) },
          patchHeaders,
        );
      }
      showToast('success', {
        id: 'toast.settings.data_saved.success',
        defaultMessage: 'Data was saved successfully',
      });
      return generalResp?.data;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      showToast('error', {
        id: 'toast.registration_settings.set.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
